import React from 'react'
import InputMask from 'react-input-mask'

import * as yup from 'yup'

import { Box, Stack, InputGroup, FormLabel } from '@chakra-ui/react'
import {
  Button,
  Typography,
  Form,
  Input,
  Select,
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@pnld/components-web'

import {
  useCreateUserController,
  useProfilesController,
  useUsersController,
} from './controller'

interface ICreateUserModalProps {
  isOpen: boolean
  onClose: () => void
  refetchData: () => Promise<void>
}

interface IFormValues {
  cpf: string
  email: string
  profile: string
}

const initialValues: IFormValues = {
  cpf: '',
  email: '',
  profile: '',
}

const validationSchema = yup.object().shape({
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido'),
  email: yup
    .string()
    .email('Por favor, digite um email válido')
    .required('Campo obrigatório'),
  // .matches(/@gov\.br$/, 'O email deve ser do domínio gov.br'),
  profile: yup.string().required('Campo obrigatório'),
})

const CreateUserModal = ({
  isOpen,
  onClose,
  refetchData,
}: ICreateUserModalProps) => {
  const { handleSubmit, isLoading } = useCreateUserController({
    onClose,
    refetchData,
  })

  const { profileOptions } = useProfilesController()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          <Typography variant="h-xs" color="brand.neutral.dark_2">
            Adicionar usuário
          </Typography>
        </ModalHeader>
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <ModalBody>
            <Stack gap={4}>
              <Box>
                <FormLabel htmlFor="cpf">CPF</FormLabel>
                <InputGroup>
                  <Input
                    as={InputMask}
                    mask="999.999.999-99"
                    id="cpf"
                    name="cpf"
                    placeholder="Digite o CPF do novo usuário"
                    variant="filled"
                  />
                </InputGroup>
              </Box>

              <Box>
                <FormLabel htmlFor="email">E-mail gov.br</FormLabel>
                <InputGroup>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Digite o e-mail usado no gov.br pelo novo usuário"
                    variant="filled"
                  />
                </InputGroup>
              </Box>

              <Box>
                <FormLabel htmlFor="profile">Perfil de usuário</FormLabel>
                <Select
                  id="profile"
                  name="profile"
                  placeholder="Selecione o perfil desejado"
                  options={profileOptions}
                />
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              label="Cancelar"
              variant="secondary"
              onClick={onClose}
              mr={3}
            />
            <Button
              label="Adicionar"
              variant="confirm"
              type="submit"
              isLoading={isLoading}
            />
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}

export default CreateUserModal
