import http from '@/api/http'

import { TGetMyAccountResponse } from './types'

export const getMyAccount = async () => {
  const response = await http.get<TGetMyAccountResponse>(
    '/users/myaccount/getme/'
  )
  return response.data
}
