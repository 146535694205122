import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { getFiles } from './services'
import { TGetFilesParams, TGetFilesResponse } from './types'

export const useQueryGetFiles = (
  filters: TGetFilesParams,
  options?: UseQueryOptions<TGetFilesResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => getFiles(filters)

  return useQuery<TGetFilesResponse, AxiosError<IErrorDTO>>(
    useQueryGetFiles.getFullQueryKey(filters),
    queryFunction,
    options
  )
}

useQueryGetFiles.getFullQueryKey = (filters: TGetFilesParams): QueryKey => [
  'files',
  filters,
]

useQueryGetFiles.queryKey = ['files']
