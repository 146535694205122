import { AxiosError } from 'axios'

import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { getMyAccount } from './services'
import { TGetMyAccountResponse } from './types'

export const useQueryGetMyAccount = (
  options?: UseQueryOptions<TGetMyAccountResponse, AxiosError<IErrorDTO>>
) => {
  return useQuery<TGetMyAccountResponse, AxiosError<IErrorDTO>>(
    useQueryGetMyAccount.queryKey,
    getMyAccount,
    options
  )
}

useQueryGetMyAccount.queryKey = ['my-account'] as const
