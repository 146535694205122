import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as yup from 'yup'

import { Box, SimpleGrid, Stack, Flex } from '@chakra-ui/react'
import { Button, Form, Input, Select, Typography } from '@pnld/components-web'

import ProfileSkeleton from '../Profile/skeletonProfile'
import { useUserInfoController } from './controller'
import S from './styles'

const UserInfo: React.FC = () => {
  const { userId } = useParams()
  const navigate = useNavigate()

  if (!userId) {
    navigate('/usuarios')
    return null
  }

  const {
    user,
    isLoading,
    initialValues,
    profileOptions,
    statusOptions,
    handleSubmit,
    isSubmitting,
  } = useUserInfoController(userId)

  if (isLoading) {
    return <ProfileSkeleton />
  }

  const validationSchema = yup.object().shape({
    perfil: yup.string().required('Campo obrigatório'),
    status: yup.string().required('Campo obrigatório'),
  })

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        padding="5"
        gap="12"
        borderRadius="0.3125rem"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <Flex
          color="white"
          borderRight="1px"
          padding="6"
          gap="6"
          align="center"
          direction={{ base: 'column', md: 'row' }}
          justify="center"
          marginBottom={2}
        >
          <S.TopContainer>
            <S.AvatarContainer>
              <S.StyledAvatar />
            </S.AvatarContainer>
            <Stack>
              <S.UserNameText>
                {user?.noUserName || 'Perfil sem nome'}
              </S.UserNameText>
              <S.UserCPFText>{user?.dsCpf}</S.UserCPFText>
            </Stack>
          </S.TopContainer>
        </Flex>

        <Box width="100%">
          <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <Flex direction="column" gap={12}>
              <Flex direction="column" gap={4}>
                <Typography variant="h-xs" color="brand.primary.dark_1">
                  Dados do usuário
                </Typography>
                <SimpleGrid columns={1} spacing={4}>
                  <Input name="name" label="Nome completo" isDisabled />
                </SimpleGrid>
                <SimpleGrid columns={2} spacing={4}>
                  <Input name="cellphone" label="Telefone" isDisabled />
                  <Input name="email" label="E-mail" isDisabled />
                </SimpleGrid>
              </Flex>
              <Flex direction="column" gap={4}>
                <Typography variant="h-xs" color="brand.primary.dark_1">
                  Permissões do usuário
                </Typography>
                <SimpleGrid columns={2} spacing={4}>
                  <Select
                    name="perfil"
                    label="Perfil"
                    options={profileOptions}
                  />
                  <Select
                    name="status"
                    label="Status"
                    options={statusOptions}
                  />
                </SimpleGrid>
              </Flex>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  label="Salvar alterações"
                  iconName="check-circle"
                  border="square"
                  size="sm"
                  type="submit"
                  isLoading={isSubmitting}
                />
              </Box>
            </Flex>
          </Form>
        </Box>
      </Flex>
    </Flex>
  )
}

export default UserInfo
