import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Avatar } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Icon,
  Image,
  Button,
  IconButton,
  useToast,
} from '@pnld/components-web'

import { useMutationPostAuthLogout } from '@/api/auth/logout/mutations'
import LogoFNDE from '@/assets/svg/fnde/colorful-v.svg'
import LogoSPA from '@/assets/svg/spa/mono.svg'
import { useSidebar } from '@/contexts/AppContext'
import { getErrorDetails } from '@/utils/error'

import S from '../../styles'
import { ISidebarContentProps } from '../../types'
import HeaderNavItem from '../HeaderNavItem'
import NavItem from '../NavItem'

const SidebarContent = ({
  onClose,
  toggleSidebar,
  sidebarWidth,
  linkItems,
  ...rest
}: ISidebarContentProps) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { isMini } = useSidebar()

  const { mutate: logout, isLoading: isLoadingLogout } =
    useMutationPostAuthLogout({
      onSuccess: () => {
        navigate('/login')
      },
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
    })

  const handleLogout = () => {
    logout()
  }

  return (
    <Flex
      direction="column"
      align="center"
      justify="space-between"
      color="white"
      borderRight="1px"
      bg="#F8F8F8"
      boxShadow="0px 1px 6px 0px #00000029"
      paddingBottom={6}
      w={{ base: 'full', md: sidebarWidth }}
      pos="fixed"
      h="full"
      transition="width 0.25s"
      {...rest}
    >
      <Box w="inherit">
        <Box bg="#D27723">
          <Flex
            alignItems="center"
            py={{ base: 4, md: isMini ? 3 : 6 }}
            px={{ base: 8, md: 0 }}
            justify={{ base: 'space-between', md: 'center' }}
          >
            <Image
              height={{ base: '36px', sm: isMini ? '24px' : '48px' }}
              src={isMini ? LogoSPA : LogoSPA}
              alt="Logo SARE"
            />
            <IconButton
              display={{ base: 'flex', md: 'none' }}
              variant="tertiary"
              iconName="close"
              onClick={onClose}
              aria-label="Fechar"
            />
          </Flex>
          <Flex
            direction={isMini ? 'column' : 'row'}
            align="center"
            width={isMini ? '48px' : 'auto'}
          >
            <HeaderNavItem to="/perfil" onClose={onClose}>
              <Avatar size="xs" />
            </HeaderNavItem>
            {!isMini && (
              <HeaderNavItem to="/configuracoes" onClose={onClose}>
                <Icon name="cog" size="xs" inverse />
              </HeaderNavItem>
            )}
            {!isMini && (
              <HeaderNavItem to="/painel/notificacoes" onClose={onClose}>
                <Icon name="bell" size="xs" inverse />
              </HeaderNavItem>
            )}
            <S.HeaderItem
              display={{ base: 'none', md: 'flex' }}
              borderBottomColor="gray.200"
              _hover={{
                color: '#D27723',
                bg: '#FFFFFF',
                cursor: 'pointer',
                borderBottomColor: '#D27723',
              }}
              onClick={toggleSidebar}
            >
              <Icon name="bars" size="xs" inverse />
            </S.HeaderItem>
          </Flex>
        </Box>
        <Box color="red.600">
          {linkItems.map(link => (
            <NavItem
              key={link.name}
              name={link.name}
              icon={link.icon}
              to={link.to}
              onClose={onClose}
            />
          ))}
          <Button
            variant="tertiary"
            w="full"
            onClick={handleLogout}
            label={!isMini ? 'Sair' : ''}
            color="#23415B"
            iconName="power-off"
          />
        </Box>
      </Box>
      <Box>
        <Image src={LogoFNDE} h={{ base: '36px', md: '60px' }} />
      </Box>
    </Flex>
  )
}

export default SidebarContent
