import { useLocation } from 'react-router-dom'

import {
  TTemplateSidebarProps,
  TTemplateBreadcrumbProps,
  TTemplateBreadcrumbStructure,
} from './types'

export const sidebarProps = {
  linkItems: [
    {
      name: 'Painel',
      icon: 'house-chimney',
      to: '/painel',
    },
    {
      name: 'Predições',
      icon: 'folder-open',
      to: '/predicoes',
    },
    {
      name: 'Envios',
      icon: 'upload',
      to: '/envios',
    },
    {
      name: 'Usuários',
      icon: 'users',
      to: '/usuarios',
    },
  ],
} as TTemplateSidebarProps

export const useBreadcrumbProps = (): TTemplateBreadcrumbProps => {
  const location = useLocation()

  const page = location.pathname
  const pagePath = page.split('/').filter(item => item !== '')

  const breadcrumbStructure = {
    painel: {
      info: {
        label: 'Painel',
        href: '/',
      },
    },
    envios: {
      info: {
        label: 'Envios',
        href: '/envios',
      },
    },
    predicoes: {
      info: {
        label: 'Predições',
        href: '/predicoes',
      },
    },
    usuarios: {
      info: {
        label: 'Usuários',
        href: '/usuarios',
      },
    },
  } as TTemplateBreadcrumbStructure

  const breadcrumbItems = []

  let currentBreadcrumbStructure = breadcrumbStructure
  for (const currentPath of pagePath) {
    if (currentBreadcrumbStructure[currentPath]) {
      breadcrumbItems.push(currentBreadcrumbStructure[currentPath].info)

      const { info, ...breadcrumbChildren } =
        currentBreadcrumbStructure[currentPath]
      currentBreadcrumbStructure = breadcrumbChildren
    }
  }

  return {
    items: breadcrumbItems,
  }
}
