import React from 'react'
import { Outlet } from 'react-router-dom'

import { Stack, Flex } from '@chakra-ui/react'
import { Spinner } from '@pnld/components-web'

import { useUser } from '@/contexts/UserContext'
import { sidebarProps, useBreadcrumbProps } from '@/data/template'
import { useSidebarItems } from '@/data/template/hooks'

import Breadcrumbs from './parts/Breadcrumbs'
import Sidebar from './parts/Sidebar'

function TemplatePage() {
  const breadcrumbProps = useBreadcrumbProps()
  const sidebarItems = useSidebarItems()
  const { isLoading } = useUser()

  if (isLoading) {
    return (
      <Flex align="center" justify="center" w="100" h="100vh">
        <Spinner size="xl" />
      </Flex>
    )
  }

  return (
    <Sidebar {...sidebarItems}>
      <Stack flexGrow={1} spacing="4">
        <Flex>
          <Breadcrumbs {...breadcrumbProps} />
        </Flex>
        <Outlet />
      </Stack>
    </Sidebar>
  )
}

export default TemplatePage
