import { ComponentStyleConfig } from '@chakra-ui/react'

export default {
  parts: ['cardBox', 'cardIcon'],
  baseStyle: {
    cardBox: {
      p: 4,
      bg: 'brand.primary.light_2',
      borderRadius: 'lg',
    },
    cardIcon: {
      name: 'home',
      variant: 'primary',
      size: 'xs',
      inverse: true,
      shaped: true,
    },
  },
  variants: {
    primary: {
      cardBox: {
        bg: 'brand.primary.light_2',
      },
      cardIcon: {
        name: 'book-reader',
        variant: 'primary',
      },
    },
    userUnique: {
      cardBox: {
        bg: 'brand.primary.light_2',
      },
      cardIcon: {
        name: 'user',
        variant: 'primary',
      },
    },
    userEdit: {
      cardBox: {
        bg: 'brand.primary.light_2',
      },
      cardIcon: {
        name: 'user-edit',
        variant: 'primary',
      },
    },
    userConfig: {
      cardBox: {
        bg: 'brand.primary.light_2',
      },
      cardIcon: {
        name: 'user-cog',
        variant: 'primary',
      },
    },
    highlight: {
      cardBox: {
        bg: 'brand.highlight.light',
      },
      cardIcon: {
        name: 'school',
        variant: 'highlight',
      },
    },
    success: {
      cardBox: {
        bg: 'brand.success.light',
      },
      cardIcon: {
        name: 'users',
        variant: 'positive',
      },
    },
    error: {
      cardBox: {
        bg: 'brand.error.light',
      },
      cardIcon: {
        name: 'users-slash',
        variant: 'negative',
      },
    },
  },
} as ComponentStyleConfig
