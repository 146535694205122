import { AxiosError } from 'axios'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { preRegisterUser, updateUser } from './services'
import {
  TGetUserByIdResponse,
  TPostPreRegisterUser,
  TUpdateUserParams,
} from './types'

export const useMutationPreRegisterUser = (
  options?: UseMutationOptions<
    TPostPreRegisterUser,
    AxiosError<IErrorDTO>,
    TPostPreRegisterUser
  >
) => {
  return useMutation<
    TPostPreRegisterUser,
    AxiosError<IErrorDTO>,
    TPostPreRegisterUser
  >(data => preRegisterUser(data), options)
}

export const useMutationUpdateUser = (
  options?: UseMutationOptions<
    TGetUserByIdResponse,
    AxiosError<IErrorDTO>,
    TUpdateUserParams
  >
) => {
  return useMutation<
    TGetUserByIdResponse,
    AxiosError<IErrorDTO>,
    TUpdateUserParams
  >(data => updateUser(data), options)
}
