import http from '@/api/http'

import { TGetCountUsersParams, TGetCountUsersResponse } from './types'

export const countUsers = async (filters: TGetCountUsersParams) => {
  const response = await http.get<TGetCountUsersResponse>(
    '/users/management/count_users/',
    {
      params: filters,
    }
  )
  return response.data
}
