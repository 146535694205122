import http from '@/api/http'

import {
  TGetUserByIdResponse,
  TGetUsersParams,
  TGetUsersResponse,
  TPostPreRegisterUser,
  TUpdateUserParams,
} from './types'

export const getUsers = async (filters: TGetUsersParams) => {
  const response = await http.get<TGetUsersResponse>('/users/management/', {
    params: filters,
  })

  return response.data
}

export const getUserById = async (coUser: number) => {
  const response = await http.get<TGetUserByIdResponse>(
    `/users/management/${coUser}/`
  )
  return response.data
}

export const preRegisterUser = async (data: TPostPreRegisterUser) => {
  const response = await http.post<TPostPreRegisterUser>(
    '/users/management/pre_registration/',
    data
  )
  return response.data
}

export const updateUser = async (params: TUpdateUserParams) => {
  const { coUser, ...data } = params
  const response = await http.put<TGetUserByIdResponse>(
    `/users/management/${coUser}/`,
    data
  )
  return response.data
}
