import React from 'react'

import { Box, SimpleGrid, Stack, Flex } from '@chakra-ui/react'
import { Skeleton } from '@pnld/components-web'

import S from './styles'

const ProfileSkeleton = () => {
  return (
    <Flex direction="column">
      <Flex
        direction="column"
        padding="5"
        gap="12"
        borderRadius="0.3125rem"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <Flex
          color="white"
          borderRight="1px"
          padding="6"
          gap="6"
          align="center"
          direction={{ base: 'column', md: 'row' }}
          justify="center"
          marginBottom={2}
        >
          <S.TopContainer>
            <S.AvatarContainer>
              <Skeleton width="80px" height="80px" borderRadius="full" />
            </S.AvatarContainer>
            <Stack>
              <Skeleton height="24px" width="200px" />
              <Skeleton height="20px" width="150px" />
            </Stack>
          </S.TopContainer>
        </Flex>

        <Box width="100%">
          <Flex direction="column" gap={12}>
            <Flex direction="column" gap={4}>
              <Skeleton height="24px" width="200px" />
              <SimpleGrid columns={1} spacing={4}>
                <Skeleton height="40px" />
              </SimpleGrid>
              <SimpleGrid columns={2} spacing={4}>
                <Skeleton height="40px" />
                <Skeleton height="40px" />
              </SimpleGrid>
            </Flex>
            <Flex direction="column" gap={4}>
              <Skeleton height="24px" width="200px" />
              <SimpleGrid columns={2} spacing={4}>
                <Skeleton height="40px" />
                <Skeleton height="40px" />
              </SimpleGrid>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

export default ProfileSkeleton
