import { useToast } from '@pnld/components-web'

import { useQueryGetMyAccount } from '@/api/users/account/queries'
import { getErrorDetails } from '@/utils/error'

export const useMyAccountController = () => {
  const toast = useToast()

  const { data: myAccount, isLoading } = useQueryGetMyAccount({
    onError: error => {
      toast({
        title: 'Erro ao carregar dados do usuário',
        description: getErrorDetails(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    },
  })

  return {
    myAccount,
    isLoading,
  }
}
