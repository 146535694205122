import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { getProfiles } from './services'
import { TGetProfilesResponse } from './types'

export const useQueryGetProfiles = (
  options?: UseQueryOptions<TGetProfilesResponse, AxiosError<IErrorDTO>>
) => {
  return useQuery<TGetProfilesResponse, AxiosError<IErrorDTO>>(
    useQueryGetProfiles.queryKey,
    getProfiles,
    options
  )
}

useQueryGetProfiles.queryKey = ['profiles'] as const
