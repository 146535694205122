import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { useUser } from '@/contexts/UserContext'

interface IPrivateRouteProps {
  allowedProfiles: string[]
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  allowedProfiles,
}) => {
  const { userProfile, isLoading } = useUser()

  return allowedProfiles.includes(userProfile || '') ? (
    <Outlet />
  ) : (
    <Navigate to="/painel" replace />
  )
}
