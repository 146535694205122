import http from '@/api/http'

import { TGetFilesParams, TGetFilesResponse } from './types'

export const getFiles = async (filters: TGetFilesParams) => {
  const response = await http.get<TGetFilesResponse>('upload/', {
    params: filters,
  })
  return response.data
}
