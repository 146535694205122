import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { getUserById, getUsers } from './services'
import {
  TGetUserByIdResponse,
  TGetUsersParams,
  TGetUsersResponse,
} from './types'

export const useQueryGetUsers = (
  filters: TGetUsersParams,
  options?: UseQueryOptions<TGetUsersResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => getUsers(filters)

  return useQuery<TGetUsersResponse, AxiosError<IErrorDTO>>(
    useQueryGetUsers.getFullQueryKey(filters),
    queryFunction,
    options
  )
}

useQueryGetUsers.getFullQueryKey = (filters: TGetUsersParams): QueryKey => [
  'users',
  filters,
]

useQueryGetUsers.queryKey = ['users']

export const useQueryGetUserById = (
  coUser: number,
  options?: UseQueryOptions<TGetUserByIdResponse, AxiosError<IErrorDTO>>
) => {
  return useQuery<TGetUserByIdResponse, AxiosError<IErrorDTO>>(
    useQueryGetUserById.getFullQueryKey(coUser),
    () => getUserById(coUser),
    options
  )
}

useQueryGetUserById.getFullQueryKey = (coUser: number): QueryKey => [
  'user',
  coUser,
]
