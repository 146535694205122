import React, { createContext, useContext } from 'react'

import { useToast } from '@pnld/components-web'

import { useQueryGetMyAccount } from '@/api/users/account/queries'
import { getErrorDetails } from '@/utils/error'

interface IUserContextData {
  userProfile: string | undefined
  isLoading: boolean
}

const UserContext = createContext<IUserContextData>({
  userProfile: undefined,
  isLoading: true,
})

export const UserContextProvider = ({ children }: React.PropsWithChildren) => {
  const toast = useToast()

  const { data: myAccount, isLoading } = useQueryGetMyAccount({
    onError: error => {
      toast({
        title: 'Erro ao carregar dados do usuário',
        description: getErrorDetails(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    },
  })

  return (
    <UserContext.Provider
      value={{
        userProfile: myAccount?.profile.noProfileName,
        isLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('Hook usado fora do contexto de UserContext')
  }

  return context
}
