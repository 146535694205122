import React from 'react'
import InputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'

import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik'
import * as yup from 'yup'

import {
  Box,
  Flex,
  Image,
  Button,
  Hide,
  Text,
  FormLabel,
  FormControl,
  InputGroup,
  Input,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react'
import { Typography } from '@pnld/components-web'

import { useMutationPostAuthLocalLogin } from '@/api/auth/local/mutations'
import Kid from '@/assets/imgs/kid.png'
import LogoFNDE from '@/assets/svg/fnde/mono.svg'
import LogoSPA from '@/assets/svg/spa/mono.svg'
import { getErrorDetails } from '@/utils/error'

interface IFormValues {
  cpf: string
  password: string
}

const DevLogin: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const { mutate: postAuthLocalLogin, isLoading: isLoadingAuthLocalLogin } =
    useMutationPostAuthLocalLogin()

  const handleSubmit = (values: IFormValues) => {
    const { cpf, password } = values
    const cpfWithoutMask = cpf.replace(/\D/g, '')

    postAuthLocalLogin(
      {
        ds_cpf: cpfWithoutMask,
        ds_password: password,
      },
      {
        onSuccess: data => {
          navigate('/')
        },
        onError: error => {
          const statusCode = error.response?.status

          if (statusCode === 401) {
            navigate('/login/acesso-negado')
            return
          }

          toast({
            status: 'error',
            title: 'Ops! Ocorreu um erro',
            description: getErrorDetails(error),
            isClosable: true,
          })
        },
      }
    )
  }

  return (
    <Flex h="100vh">
      <Hide below="lg">
        <Flex flex={1} bg="#23415B" direction="column" h="full">
          <Flex flex={1} alignItems="flex-end" justify="center">
            <Box display="grid" gap={6}>
              <Image src={LogoFNDE} h="40px" />
              <Box>
                <Typography variant="d-sm" color="#FFFFFF">
                  Transformando vidas <br /> por meio da educação.
                </Typography>
              </Box>
            </Box>
          </Flex>
          <Flex
            flex={1}
            alignItems="flex-end"
            justify="center"
            maxH={{ lg: '55%', xl: '65%' }}
          >
            <Image src={Kid} maxH="full" />
          </Flex>
        </Flex>
      </Hide>
      <Flex
        flex={1}
        bg="#D27723"
        justify="center"
        align="center"
        direction="column"
        gap={16}
      >
        <Image src={LogoSPA} h="120px" />
        <Flex direction="column" gap="4" maxW="24rem" w="full">
          <Formik
            initialValues={{
              cpf: '',
              password: '',
            }}
            validationSchema={yup.object().shape({
              cpf: yup
                .string()
                .required('Este campo é obrigatório')
                .test('test-cpf', 'O CPF está incompleto', value => {
                  return !value.includes('_')
                }),
              password: yup.string().required('Este campo é obrigatório'),
            })}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={handleSubmit}
          >
            <Form>
              <Box width="full" mb={4}>
                <FormLabel fontSize="sm">CPF</FormLabel>
                <Field name="cpf">
                  {({ field, form }: FieldProps) => {
                    return (
                      <FormControl>
                        <InputGroup>
                          <Input
                            as={InputMask}
                            mask="999.999.999-99"
                            variant="filled"
                            placeholder="Digite seu CPF"
                            value={field.value}
                            onChange={e =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                            borderColor={useColorModeValue('#23415B', 'white')}
                            color={useColorModeValue('#23415B', 'white')}
                            _placeholder={{
                              color: useColorModeValue('#23415B', 'white'),
                            }}
                          />
                        </InputGroup>
                        <ErrorMessage name="cpf">
                          {(message: string) => (
                            <Text color="red.600" fontSize="sm">
                              {message}
                            </Text>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>
              </Box>
              <Box width="full" mb={8}>
                <FormLabel fontSize="sm">Senha</FormLabel>
                <Field name="password">
                  {({ field, form }: FieldProps) => {
                    return (
                      <FormControl>
                        <InputGroup>
                          <Input
                            type="password"
                            variant="filled"
                            placeholder="Digite sua senha"
                            value={field.value}
                            onChange={e =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                            borderColor={useColorModeValue('#23415B', 'white')}
                            color={useColorModeValue('#23415B', 'white')}
                            _placeholder={{
                              color: useColorModeValue('#23415B', 'white'),
                            }}
                          />
                        </InputGroup>
                        <ErrorMessage name="password">
                          {(message: string) => (
                            <Text color="red.600" fontSize="sm">
                              {message}
                            </Text>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>
              </Box>
              <Button
                type="submit"
                color="white"
                bg="#C63637"
                _hover={{ bg: '#C63637EB' }}
                width="full"
                isLoading={isLoadingAuthLocalLogin}
              >
                Entrar como desenvolvedor
              </Button>
            </Form>
          </Formik>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default DevLogin
