import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { countUsers } from './services'
import { TGetCountUsersParams, TGetCountUsersResponse } from './types'

export const useQueryCountUsers = (
  filters: TGetCountUsersParams,
  options?: UseQueryOptions<TGetCountUsersResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => countUsers(filters)

  return useQuery<TGetCountUsersResponse, AxiosError<IErrorDTO>>(
    useQueryCountUsers.getFullQueryKey(filters),
    queryFunction,
    options
  )
}

useQueryCountUsers.getFullQueryKey = (
  filters: TGetCountUsersParams
): QueryKey => ['users', 'count', filters]

useQueryCountUsers.queryKey = ['users', 'count']
