import { FormikValues } from 'formik'

import { useToast } from '@pnld/components-web'

import { useMutationUpdateUser } from '@/api/users/mutation'
import { useQueryGetProfiles } from '@/api/users/profile/queries'
import { useQueryGetUserById } from '@/api/users/queries'
import { getErrorDetails } from '@/utils/error'

export const useUserInfoController = (userId: string) => {
  const toast = useToast()
  const coUser = Number(userId)

  const {
    data: user,
    isLoading: isLoadingUser,
    refetch,
  } = useQueryGetUserById(coUser, {
    onError: error => {
      toast({
        title: 'Erro ao carregar dados do usuário',
        description: getErrorDetails(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    },
  })

  const { data: profiles, isLoading: isLoadingProfiles } = useQueryGetProfiles()

  const mutation = useMutationUpdateUser({
    onSuccess: () => {
      toast({
        title: 'Usuário atualizado com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      refetch()
    },
    onError: error => {
      toast({
        title: 'Erro ao atualizar usuário',
        description: getErrorDetails(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    },
  })

  const initialValues = {
    name: user?.noUserName || 'Perfil sem nome',
    email: user?.dsGovbrEmail || '',
    cellphone: user?.dsCellphone || 'Sem telefone',
    perfil: user?.profile.coProfile.toString() || '',
    status: user?.dsStatus || '',
  }

  const profileOptions =
    profiles?.map(profile => ({
      value: profile.coProfile.toString(),
      label: profile.noProfileName,
    })) || []

  const statusOptions = [
    { value: 'Ativo', label: 'Ativo' },
    { value: 'Inativo', label: 'Inativo' },
  ]

  const handleSubmit = (values: FormikValues) => {
    mutation.mutate({
      coUser,
      coProfile: Number(values.perfil),
      dsStatus: values.status,
    })
  }

  return {
    user,
    isLoading: isLoadingUser || isLoadingProfiles,
    initialValues,
    profileOptions,
    statusOptions,
    handleSubmit,
    isSubmitting: mutation.isLoading,
  }
}
