import React from 'react'

import { Box, Stack, Flex } from '@chakra-ui/react'
import { Typography, Skeleton } from '@pnld/components-web'

export const TableSkeleton = () => {
  return (
    <Stack spacing={4} flex={1} h="100%">
      <Flex p={4} bg="gray.50" borderRadius="md">
        <Skeleton height="24px" width="100%" />
      </Flex>
      <Box p={4} flex={1}>
        <Skeleton height="50vh" width="100%" />
      </Box>
    </Stack>
  )
}

export const TotalizerSkeleton = () => (
  <Box h="100%" w="full" bg="white" borderRadius="md" boxShadow="sm" p={4}>
    <Stack spacing={3} w="100%">
      <Skeleton height="28px" width="60%" />
      <Skeleton height="40px" width="40%" />
    </Stack>
  </Box>
)

export const PaginationSkeleton = () => (
  <Flex justify="space-between">
    <Skeleton height="32px" width="300px" />
    <Skeleton height="32px" width="300px" />
  </Flex>
)

export const EmptyState = () => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    h="100%"
    p={8}
    gap={4}
  >
    <Typography variant="h-xs" mt={4}>
      Nenhum resultado encontrado
    </Typography>
  </Flex>
)
