import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Flex,
  Image,
  Button,
  Hide,
  Typography,
} from '@pnld/components-web'

import Kid from '@/assets/imgs/kid.png'
import LogoFNDE from '@/assets/svg/fnde/mono.svg'
import LogoSPA from '@/assets/svg/spa/mono.svg'

const Login = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState(false)
  const [isLoadingDev, setIsLoadingDev] = React.useState(false)

  const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === 'dev'

  const handleLogin = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
      navigate('/login/acesso-negado')
    }, 2000)
  }

  const handleLoginDev = () => {
    navigate('/login/desenvolvedor')
  }

  return (
    <Flex h="100vh">
      <Hide below="lg">
        <Flex flex={1} bg="#23415B" direction="column" h="full">
          <Flex flex={1} alignItems="flex-end" justify="center">
            <Box display="grid" gap={6}>
              <Image src={LogoFNDE} h="40px" />
              <Box>
                <Typography variant="d-sm" color="#FFFFFF">
                  Transformando vidas <br /> por meio da educação.
                </Typography>
              </Box>
            </Box>
          </Flex>
          <Flex
            flex={1}
            alignItems="flex-end"
            justify="center"
            maxH={{ lg: '55%', xl: '65%' }}
          >
            <Image src={Kid} maxH="full" />
          </Flex>
        </Flex>
      </Hide>
      <Flex
        flex={1}
        bg="#D27723"
        justify="center"
        align="center"
        direction="column"
        gap={16}
      >
        <Image src={LogoSPA} h="120px" />
        <Flex direction="column" gap="4">
          <Button
            w="full"
            maxW="24rem"
            label="Entrar"
            bg="#23415B"
            isLoading={isLoading}
            onClick={handleLogin}
          />
          <Button
            w="full"
            maxW="24rem"
            label="Entrar como desenvolvedor"
            bg="#C63637"
            _hover={{ bg: '#C63637EB' }}
            onClick={handleLoginDev}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Login
