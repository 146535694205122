import React, { useRef, useState, useMemo } from 'react'
import { ActionMeta } from 'react-select'

import { FormikProps, FormikValues } from 'formik'

import { IOptionProps, useToast } from '@pnld/components-web'

import { useQueryCountUsers } from '@/api/users/count/queries'
import { TGetCountUsersParams } from '@/api/users/count/types'
import { useMutationPreRegisterUser } from '@/api/users/mutation'
import { useQueryGetProfiles } from '@/api/users/profile/queries'
import { useQueryGetUsers } from '@/api/users/queries'
import { TGetUsersParams, TGetUsersResponse } from '@/api/users/types'
import { ITotalizerDisplayProps } from '@/components/Display/Totalizer/types'
import { getErrorDetails } from '@/utils/error'

export interface IUsersTableData {
  id: number
  dsCpf: string
  dsStatus: string
  profile: string
}

export const useUsersController = ({ onClose }: { onClose: () => void }) => {
  const toast = useToast()
  const formikRef = useRef<FormikProps<FormikValues>>(null)
  const [filters, setFilters] = useState<TGetUsersParams>({
    page: 1,
    pageSize: 10,
    search: undefined,
    coProfile: undefined,
    dsStatus: undefined,
  })

  const {
    data: usersData,
    isLoading,
    refetch,
  } = useQueryGetUsers(filters, {
    onError: error => {
      toast({
        title: 'Erro recebendo os dados, contate o suporte.',
        description: getErrorDetails(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    },
  })

  const tableData = useMemo(() => {
    if (!usersData?.results) return []
    return usersData.results.map(user => ({
      id: user.coUser,
      dsCpf: user.dsCpf || 'Sem CPF',
      dsStatus: user.dsStatus || 'Sem status',
      profile: user.profile.dsDescription || 'Sem perfil',
    }))
  }, [usersData?.results])

  const handleSearchChange = (search: string) => {
    setFilters(prev => ({
      ...prev,
      search: search || undefined,
      page: 1,
    }))
  }

  const handleProfileChange = (
    newValue: unknown,
    actionMeta: ActionMeta<unknown>
  ) => {
    const selectedValue = newValue as { value: string } | null

    setFilters(prev => ({
      ...prev,
      coProfile: selectedValue ? Number(selectedValue.value) : undefined,
      page: 1,
    }))
  }

  const handleStatusChange = (
    newValue: unknown,
    actionMeta: ActionMeta<unknown>
  ) => {
    const selectedValue = newValue as { value: string } | null

    setFilters(prev => ({
      ...prev,
      dsStatus: selectedValue ? selectedValue.value : undefined,
      page: 1,
    }))
  }

  return {
    userProps: {
      formikRef,
      handleSearchChange,
      handleProfileChange,
      handleStatusChange,
      tableData,
      isLoading,
      toast,
      refetch,
    },
    usersData,
    setFilters,
  }
}

const usePaginationController = (
  usersData: TGetUsersResponse | undefined,
  setFilters: React.Dispatch<React.SetStateAction<TGetUsersParams>>
) => {
  const itemsPerPageOptions: Array<IOptionProps> = useMemo(
    () => [
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '30', value: 30 },
      { label: '40', value: 40 },
      { label: '50', value: 50 },
    ],
    []
  )

  const pageJumpOptions: Array<IOptionProps> = useMemo(() => {
    const totalPages = usersData?.pagination.totalPages || 1
    return Array.from({ length: totalPages }, (_, i) => ({
      label: `${i + 1}`,
      value: i,
    }))
  }, [usersData?.pagination.totalPages])

  const handleSelectNextPage = () => {
    setFilters(prev => ({
      ...prev,
      page: (prev.page || 1) + 1,
    }))
  }

  const handleSelectPreviousPage = () => {
    setFilters(prev => ({
      ...prev,
      page: Math.max((prev.page || 1) - 1, 1),
    }))
  }

  const handleChangeItemsPerPage = (itemsPerPage: number) => {
    setFilters(prev => ({
      ...prev,
      pageSize: itemsPerPage,
      page: 1,
    }))
  }

  const handleJumpToPage = (newPageIndex: number) => {
    setFilters(prev => ({
      ...prev,
      page: newPageIndex + 1,
    }))
  }

  return {
    paginationProps: {
      currentPageIndex: (usersData?.pagination.currentPage || 1) - 1,
      itemsPerPage: usersData?.pagination.pageSize || 10,
      numberOfPages: usersData?.pagination.totalPages || 1,
      numberOfItems: usersData?.pagination.count || 0,
      choicesOfItemsPerPage: itemsPerPageOptions,
      choicesOfPageJump: pageJumpOptions,
      onSelectPreviousPage: handleSelectPreviousPage,
      onSelectNextPage: handleSelectNextPage,
      onJumpToPage: handleJumpToPage,
      onChangeItemsPerPage: handleChangeItemsPerPage,
      disableGoToPreviousPage: (usersData?.pagination.currentPage || 1) <= 1,
      disableGoToNextPage:
        (usersData?.pagination.currentPage || 1) >=
        (usersData?.pagination.totalPages || 1),
    },
  }
}

export const useTotalizersController = () => {
  const [filters, setFilters] = useState<TGetCountUsersParams>({
    search: undefined,
    coProfile: undefined,
    dsStatus: undefined,
  })

  const { data: countData, isLoading, refetch } = useQueryCountUsers(filters)

  const getVariantByProfile = (profileName: string): string => {
    switch (profileName) {
      case 'Visualizador':
        return 'userUnique'
      case 'Executor':
        return 'userEdit'
      case 'Admin':
        return 'userConfig'
      default:
        return 'userUnique'
    }
  }

  const handleProfileChange = (
    newValue: unknown,
    actionMeta: ActionMeta<unknown>
  ) => {
    const selectedValue = newValue as { value: string } | null

    setFilters(prev => ({
      ...prev,
      coProfile: selectedValue ? Number(selectedValue.value) : undefined,
    }))
  }

  const handleStatusChange = (
    newValue: unknown,
    actionMeta: ActionMeta<unknown>
  ) => {
    const selectedValue = newValue as { value: string } | null

    setFilters(prev => ({
      ...prev,
      dsStatus: selectedValue ? selectedValue.value : undefined,
    }))
  }

  const handleSearchChange = (search: string) => {
    setFilters(prev => ({
      ...prev,
      search: search || undefined,
    }))
  }

  const totalizers = useMemo(() => {
    if (!countData) return []

    return [
      ...countData.usersByStatus.map(status => ({
        number: status.total,
        label: `Usuários ${status.dsStatus.toLowerCase()}s`,
        variant: status.dsStatus === 'Ativo' ? 'success' : 'error',
      })),
      ...countData.usersByProfile.map(profile => ({
        number: profile.total,
        label: profile.coProfile.noProfileName,
        variant: getVariantByProfile(profile.coProfile.noProfileName),
      })),
    ] as ITotalizerDisplayProps[]
  }, [countData])

  return {
    totalizers,
    isLoading,
    refetch,
    handleProfileChange,
    handleStatusChange,
    handleSearchChange,
  }
}

export const useProfilesController = () => {
  const { data: profiles, isLoading } = useQueryGetProfiles()

  const profileOptions: IOptionProps[] = useMemo(
    () =>
      profiles?.map(profile => ({
        label: profile.noProfileName,
        value: profile.coProfile.toString(),
      })) || [],
    [profiles]
  )

  const visibleProfiles = profiles?.filter(p => p.stShowDashboard) || []

  const getProfileDescription = (profileId: number) => {
    return (
      profiles?.find(profile => profile.coProfile === profileId)
        ?.dsDescription || ''
    )
  }

  return {
    profiles,
    isLoading,
    profileOptions,
    visibleProfiles,
    getProfileDescription,
  }
}

export const useCreateUserController = ({
  onClose,
  refetchData,
}: {
  onClose: () => void
  refetchData: () => Promise<void>
}) => {
  const toast = useToast()

  const mutation = useMutationPreRegisterUser({
    onSuccess: async () => {
      toast({
        title: 'Usuário pré-cadastrado com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      await refetchData()
      onClose()
    },
    onError: error => {
      toast({
        title: 'Erro ao pré-cadastrar usuário',
        description: getErrorDetails(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    },
  })

  const handleSubmit = (values: FormikValues) => {
    mutation.mutate({
      dsCpf: values.cpf.replace(/\D/g, ''),
      dsGovbrEmail: values.email,
      coProfile: Number(values.profile),
    })
  }

  return {
    handleSubmit,
    isLoading: mutation.isLoading,
  }
}

export const useUsersPageController = ({
  onClose,
}: {
  onClose: () => void
}) => {
  const { userProps, usersData, setFilters } = useUsersController({ onClose })
  const { paginationProps } = usePaginationController(usersData, setFilters)

  return {
    ...userProps,
    ...paginationProps,
  }
}
